import { Entity } from 'draft-js';

const getEntityId = (node) => {
  let entityId = undefined;
  if (
    node instanceof HTMLAnchorElement
  ) {
    const entityConfig = {};
    if (node.dataset && node.dataset.mention !== undefined) {
      entityConfig['url'] = node.href;
      entityConfig['text'] = node.innerHTML;
      entityConfig['value'] = node.dataset.value;
      entityId = Entity.__create(
        'MENTION',
        'IMMUTABLE',
        entityConfig,
      );
    } else {
      entityConfig['url'] = node.getAttribute ? node.getAttribute('href') || node.href : node.href;
      entityConfig['title'] = node.innerHTML;
      entityConfig['targetOption'] = node.target;
      entityId = Entity.__create(
        'LINK',
        'MUTABLE',
        entityConfig,
      );
    }
  }
  //add for customized span tag
  if (node instanceof HTMLSpanElement) {
    const entityConfig = {};
    entityConfig['data-template-tag'] = node.getAttribute ? node.getAttribute('data-template-tag') : null;
    if (!entityConfig['data-template-tag']) { return entityId; }
    entityId = Entity.__create(
      'InvitationTemplateTag',
      'IMMUTABLE',
      entityConfig,
    );  
  }
  return entityId;
}

export default getEntityId;
